<template>
    <v-row no-gutters>
        <v-col class="main-col" lg="7">
            <v-container class="pa-4 pa-sm-12 pt-sm-8">
                <v-card width="600px" elevation="0" rounded class="pa-4 pa-sm-6">
                    <img class="mb-6" style="width: 150px;" width="232px;" :src="require(`@/assets/svg/voiceform_full_black.svg`)"/>
                    <div v-if="!invite" class="text-h5 text-md-h4 mb-6">
                        Sign Up
                    </div>
                    <div v-else class="text-h5 mb-6">👋 Welcome to Voiceform! To accept your invitation{{invitedBy? ` from ${invitedBy}`:''}}, please create your account.</div>
                    <v-form 
                        ref="form" 
                        v-model="valid"
                        lazy-validation
                    >
                        <v-text-field 
                            hide-details="auto" 
                            label="First Name" 
                            :rules="firstNameRules"
                            validate-on-blur
                            v-model="form.first_name"
                            @keyup.enter="onKeyupEnter"
                            outlined
                            class="mb-4"
                            :error-messages="this.errors && this.errors.first_name"
                            autocomplete="first-name"
                            :dense="$vuetify.breakpoint.xs"
                        >
                        </v-text-field>
                        <v-text-field 
                            hide-details="auto" 
                            label="Last Name" 
                            :rules="lastNameRules"
                            validate-on-blur
                            v-model="form.last_name"
                            @keyup.enter="onKeyupEnter"
                            class="mb-4"
                            outlined
                            :error-messages="this.errors && this.errors.last_name"
                            autocomplete="last-name"
                            :dense="$vuetify.breakpoint.xs"
                        ></v-text-field>
                        <div v-if="inviteeEmail" class="text-caption my-2">
                            <v-icon class="grey--text">mdi-information-outline</v-icon>
                            Email address from the invitation cannot be changed.
                        </div>
                        <v-text-field 
                            hide-details="auto" 
                            :label="!inviteeEmail?'Email Address': null" 
                            autocomplete="email"
                            :rules="emailRules"
                            validate-on-blur
                            v-model="form.email"
                            @keyup.enter="onKeyupEnter"
                            class="mb-4"
                            outlined
                            :error-messages="this.errors.email"
                            :disabled="!!inviteeEmail"
                            :dense="$vuetify.breakpoint.xs"
                        ></v-text-field>
                        <v-text-field 
                            hide-details="auto" 
                            label="Password" 
                            v-model="form.password"
                            @keyup.enter="onKeyupEnter($event, 3)"
                            class="mb-4"
                            outlined
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showPassword = !showPassword"
                            :rules="passwordRules"
                            validate-on-blur
                            :error-messages="this.errors.password"
                            autocomplete="new-password"
                            :dense="$vuetify.breakpoint.xs"
                        ></v-text-field>
                        <v-text-field 
                            hide-details="auto" 
                            label="Confirm Password" 
                            v-model="form.password_confirmation"
                            class="mb-4"
                            outlined
                            :type="showConfirmPassword ? 'text' : 'password'"
                            :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                            @click:append="showConfirmPassword = !showConfirmPassword"
                            @keyup.enter="handleKeyupEnter"
                            :rules="passwordRules"
                            validate-on-blur
                            :error-messages="this.errors.password"
                            autocomplete="new-password"
                            :dense="$vuetify.breakpoint.xs"
                        ></v-text-field>

                        <authentication-buttons @click="handleSignUp" :loading="loading" manualAuthButtonText="Sign up"></authentication-buttons>

                        <div class="mt-5 text-body-2 grey--text text--darken-1">Already a Voiceformer? <router-link :to="{name: 'login'}">Log in</router-link></div>
                        <div class="d-flex mt-6 align-start ">
                            <div class="caption grey--text text--darken-1">
                                By creating an account you agree to Voiceform’s
                                <a target="_blank" rel="noopener noreferrer" href="https://www.voiceform.com/privacypolicy"
                                    >Privacy Policy</a
                                >
                                and
                                <a target="_blank" rel="noopener noreferrer" href="https://www.voiceform.com/termsofservice"
                                    >Terms of Service.</a
                                >
                            </div>
                        </div>
                    </v-form>
                </v-card>
            </v-container>
        </v-col>
        <v-col lg="5" class="d-none d-lg-block sub-col pa-10">
            <div class="ma-auto">
                <div v-if="trial">
                    <div class="text-h5 ma-4 primary--text text--darken-1">Get started with a 15 day free trial! 🎉</div>
                    <div class="subtitle-1 ma-4 mb-8">{{freeTrial.description}}</div>
                    <div class="d-flex ma-4" v-for="(listItem, index) in freeTrial.shortFeaturesList" :key="`${index}-${listItem}-feature`">
                        <v-icon class="mr-2">mdi-check</v-icon>
                        <div class="text-body-1">{{listItem}}</div>
                    </div>
                    <div class="ma-4 mt-6">
                        <div class="text-body-1">...and more</div>
                    </div>
                </div>
                <img v-if="phonicTransition" class="mt-10 ml-5" style="width: 487px;" width="487px" src="https://cdn.voiceform.com/media/phonic_transition.png"/>
                <img v-else class="mt-10 ml-5" style="width: 487px;" width="487px" :src="require(`@/assets/svg/records-illustration.svg`)"/>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import CustomInput from "@/components/elements/CustomInput.vue";
import { mapActions, mapMutations } from "vuex";
import { freeTrial } from '../utils/plansContext'
import AuthenticationButtons from './AuthenticationButtons.vue';

export default {
    name: "SignUp",
    components: { CustomInput, AuthenticationButtons },
    props: {
        invite: {
            default: false
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            trial: !!this.$route.query && this.$route.query.trial==='true',
            price: !!this.$route.query &&  this.$route.query.price || null,
            invitationHash: !!this.$route.query &&  this.$route.query.invitation_hash || null,
            form: {
                first_name: null,
                last_name: null,
                email: !!this.$route.query && this.$route.query.invitee_email || null,
                password: null,
                password_confirmation: null,
            },
            errors: {},
            user: [],
            freeTrial,
            firstNameRules: [
                value => !!value || 'Required.',
            ],
            lastNameRules: [
                value => !!value || 'Required.'
            ],
            emailRules: [
                value => !!value || 'E-mail is required',
                value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
            ],
            showPassword: false,
            passwordRules: [
                value => !!value || 'Required',
                value => (value && value.length>=8) || "The password must be at least 8 characters long.",
                value => (value && /^(?=.*[0-9])(?=.*[a-zA-Z])/.test(value) ) || "The password must be alphanumeric.",
            ],
            showConfirmPassword: false
        };
    },
    watch: {
        'form.first_name': function (){
            if(this.errors && this.errors.first_name){
                this.errors.first_name = null
            }
        },
        'form.last_name': function (){
            if(this.errors && this.errors.last_name){
                this.errors.last_name = null
            }
        },
        'form.email': function (){
            if(this.errors && this.errors.email){
                this.errors.email = null
            }
        },
        'form.password': function (){
            if(this.errors && this.errors.password){
                this.errors.password = null
            }
        },
        'form.password_confirmation': function (){
            if(this.errors && this.errors.password){
                this.errors.password = null
            }
        },
    },
    computed: {
        invitedBy(){
            return !!this.$route.query && this.$route.query.invited_by || ''
        },
        inviteeEmail(){
            return !!this.$route.query && this.$route.query.invitee_email || ''
        },
        phonicTransition(){
            return !!this.$route.query && this.$route.query.utm_source=== 'phonic' || false
        }
    },
    methods: {
        ...mapActions({
            signUp: "auth/signUp",
        }),
        ...mapMutations(['showSnackBar']),
        async handleSignUp() {
            _cio && _cio.track('sign_up.click')
            const valid = this.$refs.form.validate()
            if(this.form.email){
                _cio && _cio.identify({
                    email: this.form.email
                })
            }

            if(!valid){
               return
            }

            if(this.form.password !== this.form.password_confirmation){
                return this.errors = {...this.errors, password: "Password doesn't match."}
            }

            let data = this.form;
            if(this.trial){
                data['trial']=this.trial;
            }

            if(this.price){
                data['price']= this.price;
            }

            if(this.invite){
                data['invitation_hash'] = this.invitationHash
            }

            if(this.$route.query.cd){
                data['cd'] = this.$route.query.cd
            }

            if(this.$route.query.utm_source){
                data['utm_source'] = this.$route.query.utm_source
            }

            try {
                this.loading = true;
                await this.$auth.register({ data })
                await this.$auth.load()
                if (this.$auth.ready()) {
                    _cio && _cio.identify({
                        ...this.$auth.user()
                    })

                    _cio && _cio.track('sign_up.success')

                    this.$gtag.event('sign_up');
                    
                    if(this.trial){
                        this.$gtag.event('free_trial_start');
                        this.$gtag.event('free_trial_start', {
                            'event_label': 'free_trial',
                            'non_interaction': true
                        });
                    }


                    this.$router.push({ name: "user-info" });
                }
            } catch (error) {
                this.loading = false;

                const errorData = error?.response?.data?.errors || null
                _cio && _cio.track('sign_up.failed', {
                    error: errorData
                })
                if(errorData){
                    return this.errors = errorData
                }
                this.showSnackBar({
                    text: 'Data invalid. Please check and try again.', 
                    color: 'error', 
                    timeout: 2000
                })
            }
        },
        async handleKeyupEnter(event) {
            if(!this.form.loading) {
                await this.handleSignUp();
            } else {
                this.onKeyupEnter(event);
            }
        },
        onKeyupEnter(e, offset=2) {
            const form = e.target.form;
            const index = Array.prototype.indexOf.call(form, e.target);
            // offset = 2: for first_name, last_name and email
            // offset = 3: for password
            form.elements[index + offset].focus();
            e.preventDefault();
        },
    },
};
</script>

<style lang="scss">
    :root {
        --overflow: hidden;
    }
</style>

<style scoped lang="scss">
    .sub-col {
        background-color: var(--v-primary-lighten5);
    }
    .main-col{
        position: relative;
        box-shadow: -20px 0px 30px 75px rgba(0, 0, 0, 0.05);
        height: 100vh;
        overflow: scroll;
    }
</style>
